import React, { useState } from 'react';
import constants from '../../constants';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tooltip from "./Tooltip";

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const Service = ({ image, imageWebp, imageSrcSet, title, list, linkText, linkTo }) => {
  const [isHovered, setIsHovered] = useState(false);
  const useWebP = isWebPSupported();

  return (
    <div className="col-lg-4 col-md-7 col-sm-9 mb-4" >
      <div className="single_pricing d-flex flex-column h-100">
      <Link to={linkTo} style={{ textDecoration: 'none' }}>
        <div
          className="pricing_wrapper"
          style={{ position: "relative", padding: "0px" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="information_div">
            <img 
              src={useWebP ? imageWebp : image} alt="courses"
              srcSet={useWebP ? null : imageSrcSet} />
            <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  cursor: "pointer",
                  width: "100%",
                  height: "10%",
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  fontSize: "12px",
                  textShadow: "1px 1px 1px black"
                }}
              >
                <FormattedMessage id='onepager.services.clients.info' defaultMessage='Otvori za više informacija' />
              </div>
            {isHovered && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="pricing_btn">
                  <button href={linkTo} style={{ textDecoration: "none", color: "#ffffff", backgroundColor: "#CE2F3B", position: "absolute", bottom: "50px", left: 0 }}>
                    {linkText}
                  </button>
                </div>
              </div>
            )}
          </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const Services = () => {
  const servicesData = [
    {
      image: 'assets/images/background/medium/medium_courses-1.jpg',
      imageSrcSet: [
        'assets/images/background/low/low_courses-1.jpg 654w',
        'assets/images/background/medium/medium_courses-1.jpg 1308w',
        'assets/images/background/high/high_courses-1.jpg 1962w'],
      imageWebp: 'assets/images/background/webp/courses-1.webp',
      title: <FormattedMessage id='onepager.services.clients.title' defaultMessage='Osobni trening' />,
      list: [],
      linkText: <FormattedMessage id='onepager.services.clients.linkText' defaultMessage='Klijenti' />,
      linkTo: constants.routes.CLIENTS.path,
    },
    {
      image: 'assets/images/background/medium/medium_08012024_Coach Tribe_coaches_18.jpg',
      imageSrcSet: [
        'assets/images/background/low/low_08012024_Coach Tribe_coaches_18.jpg 654w',
        'assets/images/background/medium/medium_08012024_Coach Tribe_coaches_18.jpg 1308w',
        'assets/images/background/high/high_08012024_Coach Tribe_coaches_18.jpg 1962w'],
      imageWebp: 'assets/images/background/webp/08012024_Coach Tribe_coaches_18.webp',
      title: <FormattedMessage id='onepager.services.trainers.title' defaultMessage='Poslovna rješenja za trenere' />,
      list: [],
      linkText: <FormattedMessage id='onepager.services.trainers.linkText' defaultMessage='Treneri' />,
      linkTo: constants.routes.TRAINERS.path,
    },
    {
      image: 'assets/images/background/medium/medium_fitnes-centri.jpg',
      imageSrcSet: [
        'assets/images/background/low/low_fitnes-centri.jpg 654w',
        'assets/images/background/medium/medium_fitnes-centri.jpg 1308w',
        'assets/images/background/high/high_fitnes-centri.jpg 1962w'],
      imageWebp: 'assets/images/background/webp/fitnes-centri.webp',
      title: <FormattedMessage id='onepager.services.solutions.title' defaultMessage='Unapređenje poslovanja' />,
      list: [],
      linkText: <FormattedMessage id='onepager.services.solutions.linkText' defaultMessage='Unapređenje poslovanja' />,
      linkTo: constants.routes.SOLUTIONS.path,
    },
  ];

  return (
    <section id="courses" className="courses_area pt-105">
      <div className="my-custom-row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="section_title text-center pb-25">
            <span className="line"></span>
            <h3 className="title" style={{color: 'black'}}><FormattedMessage id='onepager.services.title' defaultMessage='Naše usluge' /></h3>
            <p><FormattedMessage id="onepager.services.subtitle" defaultMessage='Klikom na slike otvorite naše usluge' /></p>
          </div>
        </div>
      </div>
      <div className="padding-style-divs">
        <div className="my-custom-row justify-content-center">
          {servicesData.map((service, index) => (
            <Service key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
