// TeamSlider.js
import React, { useState } from 'react';
import TeamMember from './TeamMember';
import teamData from '../onepager/teamData';
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import constants from '../../constants';

const isWebPSupported = () => {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

const TeamSlider = () => {

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [isHovered, setHovered] = useState(false);
  const supportsWebP = isWebPSupported();

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
      setHovered(false);
  }

  const buttonStyle = {
    backgroundColor: isHovered ? 'rgba(88, 95, 110, 0.8)' : '#000000',
    color: '#ffffff',
    fontSize: '18px',
    borderRadius: '10%',
    padding: '10px 20px',
    transition: 'background-color 0.3s ease',
  };

  const openModal = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeModalOutside = (event) => {
    if (event.target.id === 'imageModal') {
      closeModal();
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section id="team" className="team_area pt-105 pb-80">
        <div className="my-custom-row justify-content-center">
          <div className="col-lg-6 col-md-8">
              <div className="section_title text-center pb-55">
              <span className="line"></span>
              <h3 className="title" style={{ color: '#000000' }}><FormattedMessage id='onepager.team.title' defaultMessage='Naš tim' /></h3>
              <p style={{ color: '#323435' }}><FormattedMessage id='onepager.team.description' defaultMessage='Upoznajte naše trenere' /></p>
              </div>
          </div>
        </div>

        <div className="padding-style-divs">
          <Carousel responsive={responsive}>
              {teamData.map((teamMember, index) => (
              <div key={index}>
                  <TeamMember 
                    {...teamMember} 
                    openModal={openModal} 
                    />
              </div>
              ))}
          </Carousel>
        </div>
        
        <div className="container">
            <div className="my-custom-row justify-content-center">
                <div className="col-md-6 text-center">
                    <Link to={constants.routes.TEAM.path} style={{ textDecoration: 'none' }}>
                        <button
                              className="btn btn-black btn-round"
                              style={buttonStyle}
                              onMouseOver={handleMouseOver}
                              onMouseOut={handleMouseOut}
                          ><FormattedMessage id='onepager.team.title' defaultMessage='Naš tim' /> 
                        </button>
                    </Link>
                </div>
            </div>
        </div>

        {modalVisible && (
          <div id="imageModal" className="modal" style={{ display: 'block' }} onClick={closeModalOutside}>
            <div className="modal-content" onClick={closeModal}>
              <span className="close" onClick={closeModal}>&times;</span>
              <div id="imageViewer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/* For Desktop (Width >= 768px) */}
                {window.innerWidth >= 768 && (
                  <>
                    <div style={{ flex: '1' }}>
                      <h3>{selectedTeamMember.name}</h3>
                      <p>{selectedTeamMember.role}</p>
                      <img 
                        src={supportsWebP ? selectedTeamMember.imagePathWebp : selectedTeamMember.imagePath}
                        alt="Team Member"
                        style={{ width: '80%', height: 'auto' }} />  
                    </div>
                    <div style={{ flex: '1', textAlign: 'left' }}>
                      <p style={{ fontSize: "12px", whiteSpace: 'pre-line' }}>{selectedTeamMember.text}</p>
                      <img src={selectedTeamMember.imageQr} alt="QR Code" className="qr-code" style={{ maxWidth: '30%', height: 'auto' }} />
                      <p><FormattedMessage id='onepager.team.contactme' defaultMessage='Kontaktirajte me' /></p>
                    </div>
                  </>
                )}
                {/* For Mobile (Width < 768px) */}
                {window.innerWidth < 768 && (
                  <div style={{ flex: '1', textAlign: 'left' }}>
                    <h3>{selectedTeamMember.name}</h3>
                    <p>{selectedTeamMember.role}</p>
                    <p style={{ fontSize: "12px", whiteSpace: 'pre-line' }}>{selectedTeamMember.text}</p>
                    <a href={selectedTeamMember.linkQr} style={{color: "#CE2F3B"}} target="_blank" rel="noopener noreferrer" className="qr-code-anchor">
                      <FormattedMessage id='onepager.team.contactme' defaultMessage='Kontaktirajte me' />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </section>
  );
};

export default TeamSlider;
