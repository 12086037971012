import * as authService from './loginService'
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'TOKEN_KEY';
let token = localStorage.getItem(TOKEN_KEY);

function saveToken(t) {
  token = t;
  localStorage.setItem(TOKEN_KEY, t);
}

export function getToken() {
  return token;
}

function removeToken() {
  token = undefined;
  localStorage.removeItem(TOKEN_KEY);
}

export async function signIn(email, password) {
  const { token } = await authService.signIn(email, password);
  saveToken(token);
  return _getUserInfo(token);
}

export async function signOut() {
  removeToken();
}

export function getUserInfo() {
  const token = getToken();
  if (token) return _getUserInfo(token);
  return null;
}

function _getUserInfo(token) {
  const { RoleId, ...rest } = jwtDecode(token);
  return { roleId: parseInt(RoleId), ...rest };
}