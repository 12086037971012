import PropTypes from 'prop-types';
import { useLocalization } from '../../../contexts/localizationContext';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, getMessagesByLocale } from '../localization';

export default function IntlWrapper({ children }) {
  const localization = useLocalization();

  const handleError = (error) => {
    console.error(error);
  };

  return (
    <IntlProvider
      locale={localization.locale}
      messages={getMessagesByLocale(localization.locale)}
      defaultLocale={DEFAULT_LOCALE}
      onError={handleError}
    >
      {children}
    </IntlProvider>
  );
}

IntlWrapper.propTypes = {
  children: PropTypes.element.isRequired
};