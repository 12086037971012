import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Language from './Language';
import constants from './../../constants';
import { FormattedMessage } from 'react-intl';
import './HeaderStyle.css';
import { useNavbar } from './../../contexts/navbarContext';

const HeaderServices = () => {
  const { expanded, toggleNavbar, styles } = useNavbar();
  const [navbarOpacity, setNavbarOpacity] = useState(0.8);

  const linkStyle = {
    color: '#323435',
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const memoizedToggleNavbar = useCallback(() => {
    if (expanded) {
      toggleNavbar();
    }
  }, [expanded, toggleNavbar]);

  return (
    <header className={`header-area ${expanded ? 'sticky' : ''}`}>
      <div className={`navbar-area fixed-top ${!expanded ? 'expanded' : ''}`}
        style={{ opacity: navbarOpacity, pointerEvents: navbarOpacity === 0 ? 'none' : 'auto' }}>       
          <Navbar expanded={expanded} expand="lg">
            <Navbar.Brand as={Link} to={constants.routes.HOME.path} className="d-inline-block align-top">
              <img className="header-logo" src="assets/images/logo/logo.svg" alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={toggleNavbar}>
              <span className="toggler-icon"></span>
              <span className="toggler-icon"></span>
              <span className="toggler-icon"></span>
            </Navbar.Toggle>

            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="ml-auto align-items-center">
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.home' defaultMessage='Početna' /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.aboutus' defaultMessage='O nama' /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.services' defaultMessage='Usluge' /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.team' defaultMessage='Tim' /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.partners' defaultMessage='Partneri' /></Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to= { constants.routes.HOME.path } style={linkStyle} className="nav-link" onClick={memoizedToggleNavbar}><FormattedMessage id='onepager.header.contact' defaultMessage='Kontakt' /></Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Language closeNavbar={memoizedToggleNavbar} />
                </Nav.Item> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
      </div>
    </header>
  );
};

export default HeaderServices;
