import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import constants from './../../../constants';

const PolicyLinks = () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Link to={constants.routes.PRIVACY.path} style={{color: "#CE2F3B"}}>
      <FormattedMessage id="onepager.policy.privacy" defaultMessage="Politika zaštite osobnih podataka" />
    </Link>
    <span style={{ margin: '0 10px' }}>|</span>
    <Link to={constants.routes.TERMS.path} style={{color: "#CE2F3B"}}>
      <FormattedMessage id="onepager.policy.terms" defaultMessage="Uvjeti korištenja" />
    </Link>
  </div>
);

export default PolicyLinks;