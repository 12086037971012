import React, { useState, useEffect } from 'react';
import { signIn } from './../../../features/auth/loginService';
import { FormattedMessage } from 'react-intl';
import { useNavigate, Navigate } from 'react-router-dom';
import constants from '../../../constants';
import './LoginForm.css';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [navigateToDashboard, setNavigateToDashboard] = useState(false);


  //if user already has valid token
  useEffect(() => {
    const token = localStorage.getItem('TOKEN_KEY');

    if (token) {
      setNavigateToDashboard(true);
    }
  }, []);

  const handleLogin = async () => {
    try {
      const response = await signIn(username, password);
  
      // Check for successful login
      if (response && response.Token) {
        localStorage.setItem('TOKEN_KEY', response.Token);
        setNavigateToDashboard(true);
      } else {
        setError(
          <FormattedMessage
            id="features.auth.login.credentials"
            defaultMessage="Neispravan email i lozinka"
          />
        );
      }
    } catch (error) {
      setError(
        <FormattedMessage
          id="features.auth.login.failed"
          defaultMessage="Prijava nije uspjela"
        />
      );
    }
  };

  if (navigateToDashboard) {
    return <Navigate to={constants.routes.DASHBOARD.path} />;
  }

  return (
    <section className="login_area pt-105 pb-120 bg_cover">
      <div className="container">
        <div className="my-custom-row justify-content-center">
          <div className="col-md-6">
            <div className="login-form-container">
            <div className="section_title section_title_2 text-center pb-25">
              <span className="line"></span>
              <h3 className="title" style={{color: 'black'}}><FormattedMessage id='features.auth.login.login' defaultMessage='Prijava' /></h3>
            </div>
              <div className="mb-3">
                <label htmlFor="username" className="form-label"><FormattedMessage id='features.auth.login.username' defaultMessage='Korisničko ime:' /></label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label"><FormattedMessage id='features.auth.login.password' defaultMessage='Lozinka:' /></label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <button className="btn btn-primary" onClick={handleLogin}><FormattedMessage id='features.auth.login.login' defaultMessage='Prijava' /></button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
