const unsplashPhotos = [
  {
    id: "/assets/gallery/medium/medium_nikola-dino-badovinac-color.png",
    idWebp: "/assets/gallery/webp/nikola-dino-badovinac-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_slaven-krajacic-color.png",
    idWebp: "/assets/gallery/webp/slaven-krajacic-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_ena-stefanov-color.png",
    idWebp: "/assets/gallery/webp/ena-stefanov-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_antonija-jelecanin-color.png",
    idWebp: "/assets/gallery/webp/antonija-jelecanin-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_tommi-lovak-color.png",
    idWebp: "/assets/gallery/webp/tommi-lovak-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_ivica-jurkic-color.png",
    idWebp: "/assets/gallery/webp/ivica-jurkic-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_stjepan-zabcic-color.png",
    idWebp: "/assets/gallery/webp/stjepan-zabcic-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_marko-sango-color.png",
    idWebp: "/assets/gallery/webp/marko-sango-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_toni-solak-color.png",
    idWebp: "/assets/gallery/webp/toni-solak-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_tomislav-durovic-color.png",
    idWebp: "/assets/gallery/webp/tomislav-durovic-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_bruno-hemen-color.png",
    idWebp: "/assets/gallery/webp/bruno-hemen-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_marin-bago-color.png",
    idWebp: "/assets/gallery/webp/marin-bago-color.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_2.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_2.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_3.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_3.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_5.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_5.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_8.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_8.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_13.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_13.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_15.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_15.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_16.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_16.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_19.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_19.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_24.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_24.webp",
    width: 1320,
    height: 880,
  },
  {
    id: "/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_25.jpg",
    idWebp: "/assets/gallery/webp/08012024_Coach Tribe_coaches_25.webp",
    width: 1320,
    height: 880,
  }
];


function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const useWebP = isWebPSupported();

export const slides = unsplashPhotos.map((photo) => {
  return {
    src: useWebP ? photo.idWebp : photo.id,
    width: photo.width,
    height: photo.height,
  };
});

export const advancedSlides = Array.from({ length: 21 }, (_, index) => ({ ...slides[index] }));

export default slides;
