import React, { useState } from 'react';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import TrainersAboutUs from '../components/onepager/TrainersAboutUs';
import { NavbarProvider } from '../contexts/navbarContext';
import HeaderServices from '../components/onepager/HeaderServices';

const Trainers = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Loader loading={loading} setLoading={setLoading}>
      <NavbarProvider>
        <div style={{backgroundColor: '#F5F5F5'}}>
          <HeaderServices />
          <TrainersAboutUs />
          <Footer />
          <BackToTop />
          {/* Add your content for the Clients page */}
        </div>
      </NavbarProvider>
    </Loader>
  );
};

export default Trainers;