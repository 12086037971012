import React from 'react';
import { Layout, Menu, Divider, Avatar, Space, Button } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import './Sidebar.css';
import BlogPostForm from '../../../components/features/blog/BlogPostForm'

const { Sider } = Layout;

const Sidebar = ({ collapsed, handleMenuItemClick }) => {
  const menuItems = [
    { key: '1', icon: <UserOutlined />, label: 'Blog' , component: <BlogPostForm /> },
    { key: '2', icon: <VideoCameraOutlined />, label: 'Option 2' },
    { key: '3', icon: <UploadOutlined />, label: 'Option 3' },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth={0}
      width={200}
    >
      <div className="logo-container">
        <img src="assets/images/logo/logo.svg" style={{ width: '175px', height: '75px' }} alt="Logo" />
      </div>

      {/* Separator */}
      <Divider style={{ margin: '10px 0', background: '#fff' }} />


      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        {menuItems.map(item => (
          <Menu.Item key={item.key} icon={item.icon} onClick={() => handleMenuItemClick(item.component)}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
