export default Object.freeze({
    ADMIN: {
      id: 1,
      name: 'admin',
    },
    MODERATOR: {
      id: 2,
      name: 'moderator'
    },
    TRAINER: {
      id: 3,
      name: 'trainer'
    },
    USER: {
      id: 4,
      name: 'user'
    }
  });