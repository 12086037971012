import React, { useEffect } from 'react';
import Preloader from './Preloader';

const Loader = ({ loading, setLoading, children }) => {
    useEffect(() => {
      // Simulate data loading after 0.5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);
  
      // Clear the timer on component unmount
      return () => {
        clearTimeout(timer);
      };
    }, [setLoading]);
  
    return <>{loading ? <Preloader /> : children}</>;
  };

export default Loader;