import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const MainContent = ({ selectedComponent }) => {
  return (
    <Content style={{ margin: '24px 16px 0', overflow: 'initial', flexGrow: 1 }}>
      <div className="site-layout-background" style={{ padding: 24, textAlign: 'center', minHeight: '100vh' }}>
        {/* Add your main content here */}
        {selectedComponent}
      </div>
    </Content>
  );
};

export default MainContent;
