import axios from 'axios';
import constants from '../../constants';
import config from '../../config';

const { apiURL } = config;

export async function signIn(email, password) {
  try {
    const response = await axios.post(`${apiURL}${constants.endpoints.LOGIN}`, { email, password });
    return response.data;
  } catch (error) {
    if (!error.response) throw error;
    const { status } = error.response;
    if (status >= 400 && status < 500) {
      throw new Error('login.signIn.msg.error');
    }
  }
}

export async function resetPassword(email) {
  await axios.post(`${apiURL}${constants.endpoints.RESET_PASSWORD}`, { email });
}