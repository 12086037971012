import { Navigate, useRoutes } from 'react-router-dom';
import constants from '../../constants';
import Clients from '../../pages/Clients';
import Trainers from '../../pages/Trainers';
import Solutions from '../../pages/Solutions';
import Page404 from '../../pages/Page404';
import Home from '../../pages/Home';
import TeamPage from '../../pages/TeamPage';
import Login from '../../pages/Login';
import Dashboard from '../../pages/authpages/dashboard/Dashboard';
import RequireAuth from './RequireAuth';
import PersonalPolicy from '../../pages/PersonalPolicy';
import TermsOfUse from '../../pages/TermsOfUse';

const Router = () => {
  return useRoutes([
    {
      path: constants.routes.DASHBOARD.path,
      element: <RequireAuth><Dashboard /></RequireAuth>
    },

    {
      path: constants.routes.HOME.path,
      element: <Home />,
    },
    { path: constants.routes.CLIENTS.path, element: <Clients /> },
    { path: constants.routes.TRAINERS.path, element: <Trainers /> },
    { path: constants.routes.SOLUTIONS.path, element: <Solutions /> },
    { path: constants.routes.NOTFOUND.path, element: <Page404 /> },
    { path: constants.routes.TEAM.path, element: <TeamPage /> },
    { path: constants.routes.PRIVACY.path, element: <PersonalPolicy /> },
    { path: constants.routes.TERMS.path, element: <TermsOfUse /> },
    { path: constants.routes.LOGIN.path, element: <Login /> },

    { path: '*', element: <Navigate to={constants.routes.NOTFOUND.path} replace /> },
  ]);
};

export default Router;
