import React, { useState } from 'react';
import Switch from 'react-switch';
import './CookieConsent.css';

const CookieConsent = ({ onAcceptAll, onAcceptMandatory }) => {
  const [showModal, setShowModal] = useState(false);
  const [youtubeCookies, setYoutubeCookies] = useState(true);
  const [languageCookie, setLanguageCookie] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(false);
  const [analyticsCookies, setAnalyticsCookies] = useState(false);
  const [otherCookies, setOtherCookies] = useState(false);

  const handleMoreInfo = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent clicks on modal from propagating to parent
  };

  const handleAcceptAll = () => {
    onAcceptAll();
    handleCloseModal();
  };

  return (
    <div className="cookie-consent-container">
      <div className="cookie-consent-wrapper">
        <div className="cookie-consent">
          <img src="/assets/images/logo/logo.png" alt="Logo" className="logo" />
          <p>Ova web stranica koristi kolačiće kako bi osigurala najbolje iskustvo.</p>
          <button style={{ backgroundColor: 'green' }} onClick={onAcceptAll}>Prihvati sve</button>
          <button style={{ backgroundColor: 'gray' }} onClick={handleMoreInfo}>Više informacija</button>
        </div>
      </div>
      {showModal && (
        <div className="cookie-modal-overlay" onClick={handleCloseModal}>
          <div className="cookie-modal" onClick={handleModalClick}>
          <div className="modal-cookie-content">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: "200px" }} src="/assets/images/logo/logo.png" alt="Logo" className="logo" />
                </div>
                <h3>Postavke kolačića:</h3>
                <div className="cookie-section">
                    <h4>Obavezni kolačići:</h4>
                    <div className="cookie-item">
                        <p>Youtube kolačići potrebni za pokretanje videa u pozadini stranice</p>
                        <Switch
                            checked={true}
                            onChange={() => setYoutubeCookies(!youtubeCookies)}
                            disabled={true}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                <div className="cookie-section">
                    <h4>Ostali kolačići:</h4>
                    <div className="cookie-item">
                        <p>Jezični kolačić koji će zapamtiti odabir jezika u kojem želite prikazati stranicu</p>
                        <Switch
                            checked={languageCookie}
                            onChange={() => setLanguageCookie(!languageCookie)}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                <div className="cookie-section">
                    <div className="cookie-item">
                        <p>Marketinški kolačići koji trenutno nisu u upotrebi na stranici i bit će korišteni u marketinške svrhe</p>
                        <Switch
                            checked={marketingCookies}
                            onChange={() => setMarketingCookies(!marketingCookies)}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                <div className="cookie-section">
                    <div className="cookie-item">
                        <p>Analitički kolačići koji trenutno nisu u upotrebi i koji će se koristiti za razumijevanje upotrebe stranice</p>
                        <Switch
                            checked={analyticsCookies}
                            onChange={() => setAnalyticsCookies(!analyticsCookies)}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                <div className="cookie-section">
                    <div className="cookie-item">
                        <p>Ostali kolačići koji trenutno nisu u upotrebi i koji će obuhvatiti sve ostale nespecificirane kolačiće</p>
                        <Switch
                            checked={otherCookies}
                            onChange={() => setOtherCookies(!otherCookies)}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>
                </div>
                <div className="button-container">
                    <button style={{ backgroundColor: 'green' }} onClick={() => { onAcceptMandatory(); handleCloseModal(); }}>Prihvati</button>
                    <button style={{ backgroundColor: 'gray' }} onClick={() => { handleCloseModal(); }}>Zatvori</button>
                </div>
                <span className="modal-close" onClick={handleCloseModal}>×</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;
