import React, { useState, useEffect } from 'react';
import Header from './../components/onepager/Header';
import Video from '../components/onepager/Video';
import About from './../components/onepager/About';
import Counter from './../components/onepager/Counter';
import Services from './../components/onepager/Services';
import Contact from './../components/onepager/Contact';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import ComponentDivider from '../components/onepager/ComponentDivider';
import TeamSlider from '../components/onepager/TeamSlider';
import { NavbarProvider } from './../contexts/navbarContext';
import Cookies from 'universal-cookie';
import CookieConsent from '../components/onepager/cookie/CookieConsent';
import NextSectionArrow from '../components/onepager/NextSectionArrow';
import Partners from '../components/onepager/Partners';

const Home = () => {
  {/*const cookies = new Cookies();
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    const consent = cookies.get('CookieConsent');
    if (consent) {
      setShowCookieConsent(false);
      setCookieAccepted(true);
    }
  }, [cookies]);

  const handleAcceptAll = () => {
    const cookies = new Cookies();
    const maxAge = 60 * 60 * 24 * 14;
    cookies.set('CookieConsent', true, { path: '/', maxAge });
    setCookieAccepted(true);
  };

  const handleAcceptChosen = () => {
    const cookies = new Cookies();
    const maxAge = 60 * 60 * 24 * 14;
    cookies.set('CookieConsent', true, { path: '/', maxAge });
    setCookieAccepted(true);
  };*/}

  return (
    <>
      <NavbarProvider>
        <div style={{backgroundColor: '#FFF'}}>
          {/*<Cookie />*/}
          <NextSectionArrow />
          <Header />
          <Video />
          <About />
          <Counter />
          <Services />
          <ComponentDivider />
          <TeamSlider />
          {/*<ComponentDivider />*/}
          <Partners />
          <Contact />
          {/*<ComponentDivider />*/}
          <Footer />
          <BackToTop />
        </div>
      </NavbarProvider>
    </>
  );
};
  
  export default Home;