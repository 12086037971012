import axios from 'axios';
import config from '../config';

const { apiURL } = config;
let instance;
let controller;

function createAxiosInstance() {
  controller = new AbortController();
  instance = axios.create({
    baseURL: apiURL,
    signal: controller.signal,
  });
  instance.interceptors.request.use(function (config) {
    // Implement your logic here for handling tokens if needed
    // const token = getToken();
    // if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Implement your error handling logic here
      // Example: Handle unauthorized (401) response
      // if (error.response.status === 401) {
      //   abortRequests();
      //   signOut();
      //   location.reload();
      // }
      return Promise.reject(error);
    }
  );
}

export function abortRequests() {
  if (!controller) return;
  controller.abort();
  controller = undefined;
  instance = undefined;
}

export function getInstance() {
  if (!instance) createAxiosInstance();
  return instance;
}