import React, { useState } from 'react';
import './ClientsAboutUs.css';
import ContactFormModal from './ContactFormModal';
import { FormattedMessage } from 'react-intl';

const TrainersItem = ({ number, title, instructor, onButtonClick }) => (
  <div className="col-lg-4 col-sm-6" style={{marginTop: "10px"}}>
    <div className="single_schedule mt-30 hoverable-div">
      <span className="number">{number}</span>
      <h4 className="title">{title}</h4>
      <p className="instructor">{instructor}</p>
      <button className="open-modal-button" onClick={() => { 
        onButtonClick(title);
      }}>
        <FormattedMessage id='onepager.services.button' defaultMessage='Pošalji upit' />
      </button>
    </div>
  </div>
);

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const TrainersAboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const useWebP = isWebPSupported();

  const openModal = (title) => {
    setSelectedItem(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSendEmail = (formData) => {
    closeModal();
  };

  const scheduleData = [
    { number: '01.', title: <FormattedMessage id='onepager.services.trainers.title' defaultMessage='Poslovna rješenja za trenere' />, instructor: <FormattedMessage id='onepager.services.trainers.title.text' defaultMessage='Potrebna ti je pomoć u stvaranju karijere osobnog trenera - tu smo za tebe.' /> },
    { number: '02.', title: <FormattedMessage id='onepager.services.trainers.mentoring' defaultMessage='Mentorstvo' />,  instructor: <FormattedMessage id='onepager.services.trainers.mentoring.text' defaultMessage='Unapređenje poslovanja i stvaranje Leadera - osobni razvoj trenera  1 na 1' />,  },
    { number: '03.', title: <FormattedMessage id='onepager.services.trainers.workshops' defaultMessage='Radionice' />, instructor: <FormattedMessage id='onepager.services.trainers.workshops.text' defaultMessage='Razvoj potencija trenera edukacijama iz kineziologije i komplementarnih područja' />,  },
    { number: '04.', title: <FormattedMessage id='onepager.services.trainers.cooperation' defaultMessage='Suradnje' />, instructor: <FormattedMessage id='onepager.services.trainers.cooperation.text' defaultMessage='Otvoreni smo za prijedloge i savjete, pošalji upit i dogovori sastanak.' /> },
  ];

  return (
    <section id="schedules" className="schedule_area pt-105 pb-120 bg_cover" 
      style={{backgroundImage: `url(${useWebP ? 'assets/images/background/webp/schedule_bg.webp' : 'assets/images/background/medium/medium_schedule_bg.jpg'})`}}>
      <div className="container">
        <div className="my-custom-row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section_title section_title_2 text-center pb-25">
              <span className="line"></span>
              <h3 className="title"><FormattedMessage id='onepager.services.trainers.linkText' defaultMessage='Treneri' /></h3>
              <p><FormattedMessage id='onepager.services.trainers.linkText.text' defaultMessage='Poslovna rješenja za trenere' /></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{marginRight: "0px", marginLeft: "0px"}}>
        {scheduleData.map((schedule, index) => (
          <TrainersItem key={index} {...schedule} onButtonClick={openModal} />
        ))}
      </div>

      {/* Render the modal only if isModalOpen is true */}
      {isModalOpen && (
        <ContactFormModal
          selectedItem={selectedItem}
          onClose={closeModal}
          onSend={handleSendEmail}
        />
      )}
    </section>
  );
};

export default TrainersAboutUs;
