import { getInstance } from '../../lib/axios';
import constants from '../../constants';

export async function sendMail(data) {
    try {
        const response = await getInstance().post(constants.endpoints.MAIL, data);
        return response.data;
      } catch (error) {
        console.error('Failed to send mail', error.message);
      }
}