import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import constants from '../../constants';
import { useAuth } from '../../contexts/authContext';

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to={constants.routes.LOGIN.path} state={{ from: location }} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired
};