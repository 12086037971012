import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { slides, advancedSlides } from "./slides.ts";
import { FormattedMessage } from "react-intl";
import './TeamGallery.css'
import NextSectionArrow from "../NextSectionArrow.js";

const TeamGallery = () => {
    const [open, setOpen] = useState(true);
    const [position, setPosition] = useState("bottom");
    const [width, setWidth] = useState(120);
    const [height, setHeight] = useState(80);
    const [border, setBorder] = useState(1);
    const [borderRadius, setBorderRadius] = useState(4);
    const [padding, setPadding] = useState(4);
    const [gap, setGap] = useState(16);
    const [preload, setPreload] = useState(2);
    const [showToggle, setShowToggle] = useState(true);
    
    return (
      <>
        {open && <NextSectionArrow style={{ zIndex: 9999 }} />}
        <section id="teamgallery" className="team_area pt-105 pb-80">
          <div className="container">
            <div className="my-custom-row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="section_title text-center pb-55">
                  <span className="line"></span>
                  <h3 className="title" style={{ color: '#000000' }}><FormattedMessage id='onepager.team.title2' defaultMessage='Galerija slika' /></h3>
                  <p style={{ color: '#323435' }}><FormattedMessage id='onepager.team.description2' defaultMessage='Otvorite galeriju slika' /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-custom-row justify-content-center">
            <div className="image-container">
              <img
                src="/assets/gallery/medium/medium_08012024_Coach Tribe_coaches_24.jpg"
                alt="Gallery Preview"
                style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
        </section>
    
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={advancedSlides}
          carousel={{ preload }}
            plugins={[Thumbnails, Video]}
            thumbnails={{
                position,
                width,
                height,
                border,
                borderRadius,
                padding,
                gap,
                showToggle,
            }}
          style={{ zIndex: 1 }}
        />
      </>
    );
  };
  
  export default TeamGallery;