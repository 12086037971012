import React, { useState } from 'react';
import './Video.css'

const Video = () => {
  const [videoLoading, setVideoLoading] = useState(true);
  const desktopVideos = [
    {
      src: "/assets/videos/horizontal/webm/webm_ct_1.webm",
      type: 'video/webm',
      quality: 'high'
    },
    {
      src: "/assets/videos/horizontal/mp4/high_ct_1.mp4",
      type: 'video/mp4',
      quality: 'high'
    },
    {
      src: "/assets/videos/horizontal/webm/webm_ct_2.webm",
      type: 'video/webm',
      quality: 'high'
    },
    {
      src: "/assets/videos/horizontal/mp4/high_ct_2.mp4",
      type: 'video/mp4',
      quality: 'high'
    },
  ];
  
  const mobileVideos = [
    {
      src: "/assets/videos/vertical/webm/webm_ct_1.webm",
      type: 'video/webm',
      quality: 'high'
    },
    {
      src: "/assets/videos/vertical/mp4/high_ct_1.mp4",
      type: 'video/mp4',
      quality: 'high'
    },
    {
      src: "/assets/videos/vertical/webm/webm_ct_2.webm",
      type: 'video/webm',
      quality: 'high'
    },
    {
      src: "/assets/videos/vertical/mp4/high_ct_2.mp4",
      type: 'video/mp4',
      quality: 'high'
    },
    {
      src: "/assets/videos/vertical/webm/webm_ct_3.webm",
      type: 'video/webm',
      quality: 'high'
    },
    {
      src: "/assets/videos/vertical/mp4/high_ct_3.mp4",
      type: 'video/mp4',
      quality: 'high'
    },
  ];

  const canPlayWebm = () => {
    const videoElement = document.createElement('video');
    return videoElement.canPlayType('video/webm') !== '';
  };

  const getRandomVideoSources = () => {
    const supportedVideoType = canPlayWebm() ? 'video/webm' : 'video/mp4';
    const filteredVideos = window.innerWidth < 768 ? mobileVideos.filter(video => video.type === supportedVideoType) : desktopVideos.filter(video => video.type === supportedVideoType);
    const randomIndex = Math.floor(Math.random() * filteredVideos.length);
    return filteredVideos[randomIndex];
  };

  const handleVideoLoadStart = () => {
    setVideoLoading(true);
  };

  const handleVideoLoadedData = () => {
    setVideoLoading(false);
  };


  const videoSources = getRandomVideoSources();

  return (
    <div className="wrapper">
      <div className="overlay-content position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
        <h1 className="overlay-text">
          Coachtribe - platforma za klijente, trenere i fitness centre
        </h1>
      </div>
      <div id="home" className="header-hero bg_cover d-flex align-items-center position-relative video-container">
        {videoLoading && (
          <div className="loading-indicator" style={{zIndex: 20}}>
            <div className="spinner"></div>
          </div>
        )}
        <video
          id="video-player"
          src={videoSources.src}
          type={videoSources.type}
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{ objectFit: 'cover', border: '0', pointerEvents: 'none', backgroundColor: 'black' }}
          onLoadStart={handleVideoLoadStart}
          onLoadedData={handleVideoLoadedData}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Video;