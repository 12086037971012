import React from 'react';
import { FormattedMessage } from 'react-intl';

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const About = () => {
  const useWebP = isWebPSupported();

  const backgroundStyle = {
    backgroundImage: `url(${useWebP ? 'assets/images/background/webp/about.webp' : 'assets/images/background/medium/medium_about.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    srcSet: useWebP ? null : 'assets/images/background/low/low_about.jpg 654w, assets/images/background/medium/medium_about.jpg 1308w, assets/images/background/high/high_about.jpg 1962w'
  };

  return (
    <section id="about" className="about_area pt-115 pb-120">
      <div className="about_wrapper">
      <div className="about_bg bg_cover d-none d-lg-block" style={backgroundStyle}></div>

        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-10">
              <div className="about_content">
                <h2 className="about_title" style={{ color: '#000000' }}><FormattedMessage id='onepager.about.about.title' defaultMessage='Zajedno do uspjeha!' /></h2>

                <div className="about_content_wrapper">
                  <div className="section_title">
                    <span className="line"></span>
                    <h3 className="title" style={{ color: '#000000' }}><FormattedMessage id='onepager.about.title' defaultMessage='O nama' /></h3>
                    <p style={{ color: '#323435' }}><FormattedMessage id='onepager.about.description' defaultMessage='Coach tribe je tim trenera koji uz pomoć kolega i stručnjaka iz područja  nutricionizma, fizioterapije, medicine, managementa i edukacije pomaže klijentima u ostvarenju rezultata i unapređenju kvalitete života. Naš cilj je kontinuiranom edukacijom i suradnjom sa stručnjacima iz drugih područja podići razinu kvalitete usluga osobnog treninga i stvoriti novi standard fitnessa u Hrvatskoj.' /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
