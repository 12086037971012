import React from 'react';
import { FormattedMessage } from 'react-intl';

const PrivacyPolicyComponent = () => {
  const containerStyle = {
    padding: '20px',
    border: '1px solid #ddd',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={containerStyle}>
      <h1>
        <FormattedMessage id="onepager.policy.heading" defaultMessage="POLITIKA ZAŠTITE OSOBNIH PODATAKA" />
      </h1>
      <p>
        <FormattedMessage
          id="onepager.policy.intro"
          defaultMessage="Coach Tribe, obrt za usluge, vl. Nikola Dino Badovinac, Zagreb, Horvaćanska cesta 31 C, OIB 74030608773 (dalje: COACH TRIBE) pruža niz usluga, uključujući osobni trening, plan i program treninga, korporativni fitness, kondicijsku pripremu sportaša, online trening, vanjsku suradnju, suplementaciju, planove prehrane, poslovna savjetovanja za trenere, mentorstvo, radionice, suradnje, poslovna rješenja za management, podizanje razine usluga i Customer Experience, te smanjivanje operativnih troškova na području Republike Hrvatske. COACH TRIBE obrađuje osobne podatke korisnika te se smatra voditeljem obrade osobnih podataka. Niže u tekstu možete pročitati kako postupamo sa osobnim podacima Korisnika."
        />
      </p>

      <h2>
        <FormattedMessage id="onepager.policy.userData" defaultMessage="Podaci koje prikupljamo o Korisnicima" />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.userDataInfo"
          defaultMessage="COACH TRIBE pohranjuje informacije o odabiru jezika u pregledniku korisnika. Ne prikupljamo niti pohranjujemo druge osobne podatke korisnika. Naša politika privatnosti temelji se na očuvanju privatnosti korisnika, te se sve informacije obrađuju isključivo u skladu s važećim zakonodavstvom i uz izričitu privolu korisnika ako je to potrebno za pružanje određene usluge."
        />
      </p>

      <h2>
        <FormattedMessage id="onepager.policy.useOfData" defaultMessage="Kako koristimo Vaše osobne podatke" />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.useOfDataInfo"
          defaultMessage="Vaše osobne podatke koristimo na odgovarajući način u situacijama u kojima je obrada Vaših osobnih podataka potrebna, primjerice:"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage id="onepager.policy.usage1" defaultMessage="Za odgovaranje na vaše upite" />
        </li>
        <li>
          <FormattedMessage
            id="onepager.policy.usage2"
            defaultMessage="Za automatski odgovor na uneseni email pri popunjavanju kontaktnih obrasca"
          />
        </li>
        <li>
          <FormattedMessage
            id="onepager.policy.usage3"
            defaultMessage="Za prosljeđivanje unesenih podataka na naš info mail"
          />
        </li>
      </ul>
      <p>
        <FormattedMessage
          id="onepager.policy.usageImportant"
          defaultMessage="Važno je napomenuti da se podaci pri popunjavanju kontaktnih obrasca ne pohranjuju u našem sustavu."
        />
      </p>

      <h2>
        <FormattedMessage id="onepager.policy.shareData" defaultMessage="S kime dijelimo podatke o Korisnicima?" />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.shareDataInfo"
          defaultMessage="Ne dijelimo podatke o korisnicima s trećim stranama."
        />
      </p>

      <h2>
        <FormattedMessage id="onepager.policy.userRights" defaultMessage="Prava Korisnika" />
      </h2>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToObject" defaultMessage="Pravo na prigovor:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToObjectInfo"
          defaultMessage="Ovo pravo omogućuje Korisnicima da prigovore obradi osobnih podataka u određenim situacijama."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToWithdraw" defaultMessage="Pravo na povlačenje privole:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToWithdrawInfo"
          defaultMessage="Korisnici mogu povući danu privolu u bilo kojem trenutku."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToAccess" defaultMessage="Pravo na pristup:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToAccessInfo"
          defaultMessage="Korisnici mogu zatražiti presliku informacija koje imamo o njima."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToDelete" defaultMessage="Pravo na brisanje:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToDeleteInfo"
          defaultMessage="Korisnici mogu zatražiti brisanje osobnih podataka u određenim okolnostima."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToLimitProcessing" defaultMessage="Pravo na ograničenje obrade:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToLimitProcessingInfo"
          defaultMessage="Korisnici mogu zatražiti ograničenje obrade osobnih podataka u određenim situacijama."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToRectification" defaultMessage="Pravo na ispravak:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToRectificationInfo"
          defaultMessage="Korisnici mogu zatražiti ispravak netočnih ili nepotpunih informacija."
        />
      </p>
      <p>
        <strong>
          <FormattedMessage id="onepager.policy.rightToPortability" defaultMessage="Pravo na prijenos:" />
        </strong>
        <FormattedMessage
          id="onepager.policy.rightToPortabilityInfo"
          defaultMessage="Korisnici mogu zatražiti prijenos svojih osobnih podataka drugom voditelju obrade."
        />
      </p>

      <h2>
        <FormattedMessage
          id="onepager.policy.userDataRetentionPeriod"
          defaultMessage="Razdoblje čuvanja osobnih podataka Korisnika"
        />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.userDataRetentionPeriodInfo"
          defaultMessage="Podatke ćemo čuvati u skladu s odgovarajućim propisima, pri čemu rokovi čuvanja ovise o ispunjavanju ugovorne ili zakonske obveze."
        />
      </p>

      <h2>
        <FormattedMessage id="onepager.policy.rightToComplain" defaultMessage="Pravo na pritužbu" />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.rightToComplainInfo"
          defaultMessage="Korisnik može podnijeti pritužbu Agenciji za zaštitu osobnih podataka:"
        />
      </p>
      <address>
        Agencija za zaštitu osobnih podataka
        <br />
        Martićeva ulica 14, HR - 10 000 Zagreb
        <br />
        Tel. 00385 (0)1 4609-000
        <br />
        Fax. 00385 (0)1 4609-099
        <br />
        E-mail: azop@azop.hr
        <br />
        Web: <a href="https://www.azop.hr" target="_blank" rel="noopener noreferrer">www.azop.hr</a>
      </address>

      <h2>
        <FormattedMessage id="onepager.policy.howToContactUs" defaultMessage="Kako nas kontaktirati" />
      </h2>
      <p>
        <FormattedMessage
          id="onepager.policy.howToContactUsInfo"
          defaultMessage="U slučaju da želite iskoristiti navedena prava ili povući privolu, molimo Vas da nas kontaktirate na e-mail: info@coachtribe.hr"
        />
      </p>

      <p>
        <FormattedMessage
          id="onepager.policy.effectiveDate"
          defaultMessage="Ova politika upravljanja osobnim podacima stupa na snagu 01. veljače.2024."
        />
      </p>
    </div>
  );
};

export default PrivacyPolicyComponent;
