import React, { useState } from 'react';

const isWebPSupported = () => {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

const TeamMember = ({ imagePath, imageHovered, imagePathWebp, imageHoveredWebp, imageQr, linkQr, name, role, text, instagram, facebook, linkedIn, openModal}) => {
  const [isHovered, setHovered] = useState(false);
  const supportsWebP = isWebPSupported();

  const handleUlClick = (event) => {
    event.stopPropagation();
  };

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  return (
    <div 
      className={`single_team text-center ${isHovered ? 'hovered' : ''}`} 
      onClick={() => openModal({ imagePath, imagePathWebp, name, role, text, imageQr, linkQr })}
      onMouseOver={handleMouseOver} 
      onMouseOut={handleMouseOut}
      style={{margin: '10px', boxShadow: "0px 0px 15px 0px rgba(143, 143, 143, 0.16)"}}
    >
      <div className="team_image">
      <img 
        src={supportsWebP ? (isHovered ? imageHoveredWebp : imagePathWebp) : (isHovered ? imageHovered : imagePath)} 
        alt="team" className="imageTrigger"
      />
      </div>
      <div className="team_content">
        <ul className="social" onClick={handleUlClick}>
        {facebook && (
            <li><a href={facebook} target="_blank" rel="noopener noreferrer"><i className="lni lni-facebook-filled"></i></a></li>
          )}
          {linkedIn && (
            <li><a href={linkedIn} target="_blank" rel="noopener noreferrer"><i className="lni lni-linkedin-original"></i></a></li>
          )}
          {instagram && (
            <li><a href={instagram} target="_blank" rel="noopener noreferrer"><i className="lni lni-instagram-original"></i></a></li>
          )}
            <li><a href={linkQr} target="_blank" rel="noopener noreferrer"><i className="lni lni-google"></i></a></li>
        </ul>
        <h5 className="team_name" style={{ color: '#000000' }}>{name}</h5>
        <p style={{ color: '#323435' }}>{role}</p>
      </div>
    </div>
  );
};

export default TeamMember;
