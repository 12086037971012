import React, { useState } from 'react';
import './ClientsAboutUs.css';
import ContactFormModal from './ContactFormModal';
import { FormattedMessage } from 'react-intl';

const ClientsItem = ({ number, title, instructor, onButtonClick }) => (
  <div className="col-lg-4 col-sm-6" style={{marginTop: "10px"}}>
    <div className="single_schedule mt-30 hoverable-div">
      <span className="number">{number}</span>
      <h4 className="title">{title}</h4>
      <p className="instructor">{instructor}</p>
      <button className="open-modal-button" onClick={() => { 
        onButtonClick(title);
      }}>
        <FormattedMessage id='onepager.services.button' defaultMessage='Pošalji upit' />
      </button>
    </div>
  </div>
);

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const ClientsAboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const useWebP = isWebPSupported();

  const openModal = (title) => {
    setSelectedItem(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSendEmail = (formData) => {
    closeModal();
  };

  const scheduleData = [
    { number: '01.', title: <FormattedMessage id='onepager.services.clients.title' defaultMessage='Osobni trening' />, instructor: <FormattedMessage id='onepager.services.clients.title.text' defaultMessage='Ostvarivanje Vaših ciljeva uz vodstvo trenera - od izbora vježbi prilagođenih Vama, programiranja cijelog procesa treninga do edukacije i motivacije. Nudimo Vam opciju individualnih treninga, treninga u paru ili manjih grupama (3-5)' /> },
    { number: '02.', title: <FormattedMessage id='onepager.services.clients.plan' defaultMessage='Plan i program treninga' />, instructor: <FormattedMessage id='onepager.services.clients.plan.text' defaultMessage='Namijenjeno svima koji žele prilagođen plan vježbanja uz online podršku trenera. Uključuje 2-4 osobna treninga te pismene upute kako provoditi trening.' /> },
    { number: '03.', title: <FormattedMessage id='onepager.services.clients.corparate' defaultMessage='Korporativni fitness' />, instructor: <FormattedMessage id='onepager.services.clients.corparate.text' defaultMessage='Pružamo mogućnost usluga trenera za pravne subjekte, bilo da se radi o organiziranju treninga u vašim prostorima ili pogodnosti za trening u našim centrima.' /> },
    { number: '04.', title: <FormattedMessage id='onepager.services.clients.preparation' defaultMessage='Kondicijska priprema sportaša' />, instructor: <FormattedMessage id='onepager.services.clients.preparation.text' defaultMessage='Treninzi za sportaše s ciljem razvijanja motoričkih sposobnosti i funkcionalnih kapaciteta prema specifikacijama pojedinih sportova.' /> },
    { number: '05.', title: <FormattedMessage id='onepager.services.clients.supplementation' defaultMessage='Nutricionističke usluge i suplementacija' />, instructor: <FormattedMessage id='onepager.services.clients.supplementation.text' defaultMessage='Optimizacija Vaše prehrane uz utemeljene, praktične i održive planove prehrane. Uključuju konzultacije, edukacijske materijale te individualni plan prehrane uz recepte.' /> },
    { number: '06.', title: <FormattedMessage id='onepager.services.clients.special' defaultMessage='Posebni uvjeti' />, instructor: <FormattedMessage id='onepager.services.clients.special.text' defaultMessage='Imate upit koji nije pokriven ponuđenim uslugama, tu smo za Vas' /> },
  ];

  return (
    <section id="schedules" className="schedule_area pt-105 pb-120 bg_cover" 
      style={{backgroundImage: `url(${useWebP ? 'assets/images/background/webp/ourteam-background.webp' : 'assets/images/background/medium/medium_ourteam-background.jpg'})`}}>
      <div className="container">
        <div className="my-custom-row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section_title section_title_2 text-center pb-25">
              <span className="line"></span>
              <h3 className="title"><FormattedMessage id='onepager.services.clients.linkText' defaultMessage='Klijenti' /></h3>
              <p><FormattedMessage id='onepager.services.clients.linkText.text' defaultMessage='Dodati tekst' /></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{marginRight: "0px", marginLeft: "0px"}}>
          {scheduleData.map((schedule, index) => (
            <ClientsItem key={index} {...schedule} onButtonClick={openModal} />
          ))}
        </div>

      {/* Render the modal only if isModalOpen is true */}
      {isModalOpen && (
        <ContactFormModal
          selectedItem={selectedItem}
          onClose={closeModal}
          onSend={handleSendEmail}
        />
      )}
    </section>
  );
};

export default ClientsAboutUs;
