import React from 'react';
import { GridLoader } from 'react-spinners';
import './GridLoader.css';

const SpinnerLoader = ({ loading }) => {
  return (
    <div className={`spinner-loader-container ${loading ? 'visible' : ''}`}>
      {loading && <GridLoader color="#d12d3c" loading={loading} size={15} />}
    </div>
  );
};

export default SpinnerLoader;