import React, { useState } from 'react';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import { NavbarProvider } from '../contexts/navbarContext';
import HeaderServices from '../components/onepager/HeaderServices';
import PrivacyPolicyComponent from '../components/onepager/policy/PrivacyPolicyComponent';

const PersonalPolicy = () => {

    const [loading, setLoading] = useState(true);
  
    return (
      <Loader loading={loading} setLoading={setLoading}>
        <NavbarProvider>
          <section id="about" className="about_area pt-115">
            <HeaderServices />
            <PrivacyPolicyComponent />
            <Footer />
            <BackToTop />
          </section>
        </NavbarProvider>
      </Loader>
    );
  };
  
  export default PersonalPolicy;