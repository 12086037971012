import React from 'react';
import { FormattedMessage } from 'react-intl';

const TermsOfUseComponent = () => {
  const containerStyle = {
    padding: '20px',
    border: '1px solid #ddd',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={containerStyle}>
      <h1>
        <FormattedMessage id="onepager.terms.heading" />
      </h1>

      <h2>
        <FormattedMessage id="onepager.terms.generalTermsHeading" />
      </h2>
      <p>
        <FormattedMessage id="onepager.terms.generalTerms" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms2" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms3" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms4" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms5" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms6" />
      </p>

      <p>
        <FormattedMessage id="onepager.terms.generalTerms7" />
      </p>

      <h2>
        <FormattedMessage id="onepager.terms.generalTerms8" />
      </h2>
      <p>
        <FormattedMessage id="onepager.terms.generalTerms9" />
      </p>

      <h2>
        <FormattedMessage id="onepager.terms.generalTerms10" />
      </h2>
      <p>
        <FormattedMessage id="onepager.terms.generalTerms11" />
      </p>
    </div>
  );
};

export default TermsOfUseComponent;
