import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const partnersData = [
    { 
        title: "Mofit",
        title2: "Adidas sports studio",
        url: "https://www.mofit.hr/",
        url2: "https://www.sportsstudio.com.hr/",
        src: "/assets/images/partners/mofit_logo.png",
        srcWebp: "/assets/images/partners/webp/mofit_logo.webp",
        description: <FormattedMessage id='onepager.partners.mofit.description' />
    },
    { 
        title: "Studio kut",
        url: "https://kut.studio/",
        src: "/assets/images/partners/kut_logo.png",
        src2: "/assets/images/partners/kut_black_logo.png",
        srcWebp: "/assets/images/partners/webp/kut_logo.webp",
        src2Webp: "/assets/images/partners/webp/kut_black_logo.webp",
        description: <FormattedMessage id='onepager.partners.kut.description' />
    },
    { 
        title: "Fit unit",
        url: "https://www.fit-unit.hr/",
        src: "/assets/images/partners/FU_logo.png",
        srcWebp: "/assets/images/partners/webp/FU_logo.webp",
        description: <FormattedMessage id='onepager.partners.fitunit.description' />
    },
];

function isWebPSupported() {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
}

const Partners = () => {
    const [modalData, setModalData] = useState(null);
    const useWebP = isWebPSupported();

    const openModal = (partner) => {
        setModalData(partner);
    };

    const closeModal = () => {
        setModalData(null);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        rtl: true,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const mofitPartner = partnersData.find(partner => partner.title === 'Mofit');

    const backgroundStyle = {
        backgroundImage: `url(${useWebP ? '/assets/images/background/webp/gym.webp' : '/assets/images/background/medium/medium_gym.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        srcSet: useWebP ? null : '/assets/images/background/low/low_gym.jpg 322w, /assets/images/background/medium/medium_gym.jpg 644w, /assets/images/background/high/high_gym.jpg 966w'
    };

    return (
        <section 
            id="partners" 
            className="partner-icons counter_area pt-105 pb-80" 
            style={backgroundStyle}
        >
            <div className="my-custom-row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="section_title text-center pb-25">
                        <span className="line"></span>
                        <h3 className="title" style={{color: 'white'}}>
                            <FormattedMessage id='onepager.partners.title' defaultMessage='Naši partneri' />
                        </h3>
                    </div>
                </div>
            </div>

            {mofitPartner && (
                <div onClick={() => openModal(mofitPartner)} className="text-center" style={{ zIndex: 100, position: 'relative' }}>
                    <img src={useWebP ? mofitPartner.srcWebp : mofitPartner.src} alt={mofitPartner.title} className="partner-icons-mofit"/>
                </div>
            )}

            <div className="my-custom-row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="section_title text-center pb-25">
                        <span className="line"></span>
                        <h4 className="title" style={{color: 'white'}}>
                            <FormattedMessage id='onepager.partners.title2' defaultMessage='Ostali partneri' />
                        </h4>
                    </div>
                </div>
            </div>
            <Slider {...settings}>
                {partnersData
                    .filter(partner => partner.title !== 'Mofit')
                    .map((partner, index) => (
                        <div key={index} className={`partner-slide ${partner.title === 'Mofit' ? 'larger' : 'smaller'}`}>
                            <div onClick={() => openModal(partner)} className="text-center">
                                <img src={useWebP ? partner.srcWebp : partner.src} alt={partner.title} className="partner-icon" style={{ cursor: 'pointer' }}/>
                            </div>
                        </div>
                    ))}
            </Slider>

            {/* Modal */}
            {modalData && (
                <div id="myModal" className="modal" style={{ display: "block" }}>
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2 id="modal-title" className="text-center">{modalData.title}</h2>
                        <div className="logo-container text-center">
                            <img style={{width: '200px', height: 'auto'}} src={useWebP ? (modalData.title === 'Studio kut' ? modalData.src2Webp : modalData.srcWebp) : (modalData.title === 'Studio kut' ? modalData.src2 : modalData.src)} alt="Logo" className="modal-logo" />
                        </div>
                        <div className="modal-body">
                            <div className="description-container">
                                <p id="modal-description" style={{ whiteSpace: 'pre-line' }}>{modalData.description}</p>
                                <a href={modalData.url} target="_blank" rel="noopener noreferrer" style={{color: "#CE2F3B"}}>Posijetite {modalData.title}</a>
                                {modalData.title === 'Mofit' && (
                                    <>
                                        <br />
                                        <a href={modalData.url2} target="_blank" rel="noopener noreferrer" style={{color: "#CE2F3B", marginLeft: '10px'}}>Posijetite {modalData.title2}</a>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Partners;
