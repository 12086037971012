import React, { useState } from 'react';
import './ContactFormModal.css';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { sendMail } from '../../features/webmail/webMailService';
import { useSnackbar } from 'notistack';
import SpinnerLoader from './GridLoader';

const ContactFormModal = ({ selectedItem, onClose, onSend }) => {
  const intl = useIntl();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || !email || !message) {
        // Show error message for empty fields
        enqueueSnackbar(
          <FormattedMessage
            id="onepager.services.modal.form.emptyFields"
            defaultMessage="All fields are required."
          />,
          { variant: 'error' }
        );
        return false;
      }

      if (!emailRegex.test(email)) {
          // Show error message for invalid email format
          enqueueSnackbar(
            <FormattedMessage
              id="onepager.contact.form.invalidEmail"
              defaultMessage="Please enter a valid email address."
            />,
            { variant: 'error' }
          );
          return false;
        }
      return true;
  }

  const handleSend = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      // Perform the send email action
      const result = await sendMail({
        Name: name,
        Email: email,
        Subject: intl.formatMessage({ id: selectedItem.props.id }),
        Message: message,
      });

      if (result.isMailSent) {
        // Show success message
        enqueueSnackbar(
          <FormattedMessage
            id='onepager.contact.mail.ok.message'
            defaultMessage='Mail uspješno poslan'
          />,
          { variant: 'success' }
        );
      } else {
        // Show error message
        enqueueSnackbar(
          <FormattedMessage
            id='onepager.contact.mail.notok.message'
            defaultMessage='Mail nije poslan'
          />,
          { variant: 'error' }
        );
      }
    } catch (error) {
      // Show generic error message
      enqueueSnackbar(
        <FormattedMessage
          id='onepager.contact.mail.error'
          defaultMessage='Greška pri slanju maila'
        />,
        { variant: 'error' }
      );
    }

    setLoading(false);

    onClose();
  };

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <h2><FormattedMessage id='onepager.services.modal.title' defaultMessage='Kontaktirajte nas' /></h2>
        <label htmlFor="selectedItem"><FormattedMessage id='onepager.services.modal.selected' defaultMessage='Odabrana usluga' /></label>
        <input type="text" id="selectedItem" value={intl.formatMessage({ id: selectedItem.props.id })} readOnly />
        <label htmlFor="name"><FormattedMessage id='onepager.services.modal.name' defaultMessage='Ime:' /></label>
        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        <label htmlFor="email"><FormattedMessage id='onepager.services.modal.email' defaultMessage='Email:' /></label>
        <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor="message"><FormattedMessage id='onepager.services.modal.message' defaultMessage='Poruka:' /></label>
        <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
        <button type="button" disabled={loading} onClick={handleSend}><FormattedMessage id='onepager.services.modal.send' defaultMessage='Pošalji' /></button>
      </div>
      <SpinnerLoader loading={loading} />
    </div>
  );
};

export default ContactFormModal;