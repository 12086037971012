import React from 'react';
import './ComponentDivider.css';

const ComponentDivider = () => {
  return (
    <div className="styled-divider">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 1" preserveAspectRatio="none">
        <path d="M0 0 L50 1 L100 0" fill="#000000" />
      </svg>
    </div>
  )
};

export default ComponentDivider;