import React from 'react';
import { FormattedMessage } from 'react-intl';
import PolicyLinks from './policy/PolicyLinks';

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const useWebP = isWebPSupported();

const Footer = () => (
  <section id="footer" className="footer_area" style={{ backgroundColor: '#000000' }}>
    <div className="footer_widget pt-70 pb-120">
      <div className="container">
        <div className="my-custom-row justify-content-center">
          <img className="footer_logo" src={useWebP ? "assets/images/logo/webp/footer-logo.webp" : "assets/images/logo/footer-logo.png"} alt="logo" />
        </div>
        <div className="my-custom-row justify-content-center">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="footer_info mt-45">
              <h4 className="footer_title"><FormattedMessage id='onepager.footer.contact.title' defaultMessage='Kontakt informacije' /></h4>
              <ul className="info">
                <li><FormattedMessage id='onepager.footer.contact.phone' defaultMessage='Telefon:' /> 0913334424</li>
                <li><FormattedMessage id='onepager.footer.contact.email' defaultMessage='Email:' /> info@coachtribe.hr</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="footer_social mt-45">
              <h4 className="footer_title"><FormattedMessage id='onepager.footer.contact.social' defaultMessage='Zapratite nas na' /></h4>
              <ul className="social">
                <li><a href="https://www.facebook.com/coachtribe.hr" target="_blank" rel="noopener noreferrer"><i className="lni lni-facebook-filled"></i></a></li>
                <li><a href="https://www.instagram.com/coach_tribe/" target="_blank" rel="noopener noreferrer"><i className="lni lni-instagram-original"></i></a></li>
                {/*<li><a href="/"><i className="lni lni-linkedin-original"></i></a></li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_copyright">
      <div className="container">
        <div className="copyright text-center">
          <p>Copyright &copy; 2024. CoachTribe</p>
        </div>
      </div>
    </div>
    <PolicyLinks />
  </section>
);

export default Footer;
