import React from 'react';
import { LocalizationProvider } from './contexts/localizationContext';
import { BrowserRouter } from 'react-router-dom'
import Router from './features/routing/Router';
import IntlWrapper from './features/localization/componenets/IntlWrapper';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/authContext';
import { CookiesProvider } from 'react-cookie';

const App = () => {
  return (
    <CookiesProvider>
      <LocalizationProvider>
        <IntlWrapper>
          <AuthProvider>
            <BrowserRouter>
              <SnackbarProvider maxSnack={3}>
                <Router />
              </SnackbarProvider>
            </BrowserRouter>
          </AuthProvider>
        </IntlWrapper>
      </LocalizationProvider>
    </CookiesProvider>
  );
};

export default App;
