import { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { DEFAULT_LOCALE } from '../features/localization/localization';

const LocalizationContext = createContext(null);

export function LocalizationProvider({ children }) {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [cookies, setCookie] = useCookies(['UserLanguage', 'CookieConsent']);

  useEffect(() => {
    const userLanguage = cookies.UserLanguage;
    const hasCookieConsent = cookies.CookieConsent === true;

    if (hasCookieConsent && userLanguage && userLanguage !== locale) {
      setLocale(userLanguage);
    }
  }, [cookies.UserLanguage, cookies.CookieConsent, locale, setLocale]);

  const _setLocale = (newLocale) => {
    if (newLocale === locale) return;

    if (cookies.CookieConsent === true) {
      setLocale(newLocale);
      setCookie('UserLanguage', newLocale, { path: '/', maxAge: 2592000 });
    } else {
      setLocale(newLocale); // Set to default locale if CookieConsent is not true
    }
  };

  const value = { locale, setLocale: _setLocale };

  return <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>;
}

LocalizationProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export function useLocalization() {
  return useContext(LocalizationContext);
}