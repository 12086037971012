import { createContext, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as userSessionService from '../features/auth/userSession';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(userSessionService.getUserInfo());

  const signIn = useCallback(async (email, password) => {
    const decodedJWT = await userSessionService.signIn(email, password);
    setUser(decodedJWT);
  }, [setUser]);

  const signOut = useCallback(() => {
    userSessionService.signOut();
    setUser(null);
  }, [setUser]);

  const value = { user, signIn, signOut, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export function useAuth() {
  return useContext(AuthContext);
}