import React from 'react';
import { useLocalization } from '../../contexts/localizationContext';
import Select, { components } from 'react-select';

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    {/* 
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${props.data.value.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${props.data.value.toLowerCase()}.png 2x`}
      alt={props.data.label}
    />
    */}
    {props.data.label}
  </Option>
);

const Language = ( { closeNavbar } ) => {
  const { locale, setLocale } = useLocalization();

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  const handleLanguageChange = (countryCode) => {
    changeLocale(countryCode === 'GB' ? 'en-GB' : 'hr-HR');
    // Close the Navbar using the provided function
    closeNavbar();
  };

  const options = [
    { value: 'GB', label: ' English' },
    { value: 'HR', label: ' Hrvatski' },
    // Add more options as needed
  ];

  return (
    <div>
      <Select
        defaultValue={options.find((option) => option.value === (locale === 'en-GB' ? 'GB' : 'HR'))}
        options={options}
        components={{ Option: IconOption }}
        onChange={(selectedOption) => handleLanguageChange(selectedOption.value)}
        isSearchable={false}
      />
    </div>
  );
};

export default Language;