import React, { useState } from 'react';
import HeaderServices from './../components/onepager/HeaderServices';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import PoslovnaRjesenjaAboutUs from '../components/onepager/PoslovnaRjesenjaAboutUs';
import { NavbarProvider } from '../contexts/navbarContext';

const Solutions = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Loader loading={loading} setLoading={setLoading}>
      <NavbarProvider>
          <div style={{backgroundColor: '#F5F5F5'}}>
            <HeaderServices />
          <PoslovnaRjesenjaAboutUs />
          <Footer />
          <BackToTop />
          {/* Add your content for the Clients page */}
          </div>
      </NavbarProvider>
    </Loader>
  );
};

export default Solutions;