import enEN from './locale/enEN.json';
import hrHR from './locale/hrHR.json';
import constants from '../../constants';
import config from '../../config';

export const DEFAULT_LOCALE = config.locale || constants.locales.enEN;

const messagesByLocale = {
  [constants.locales.enEN]: enEN,
  [constants.locales.hrHR]: hrHR
};

export function getMessagesByLocale(locale) {
  const messages = messagesByLocale[locale];
  if (messages) return messages;
  return messagesByLocale[DEFAULT_LOCALE];
}