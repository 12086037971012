import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import HeaderServices from '../components/onepager/HeaderServices';
import { NavbarProvider } from '../contexts/navbarContext';
import constants from '../constants';
import { FormattedMessage } from 'react-intl';

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const Page404 = () => {
  const [loading, setLoading] = useState(true);
  const useWebP = isWebPSupported();

  return (
    <Loader loading={loading} setLoading={setLoading}>
      <NavbarProvider>
        <HeaderServices />
        <section
          id="schedules"
          className="schedule_area pt-105 pb-120 bg_cover"
          style={{backgroundImage: `url(${useWebP ? 'assets/images/background/webp/testimonial_bg.webp' : 'assets/images/background/medium/medium_testimonial_bg.jpg'})`, 
            backgroundSize: 'cover', 
            backgroundAttachment: 'fixed'}}
        >
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
                backgroundColor: 'white',
                opacity: '0.3',
                border: '2px solid #ccc',
                padding: '20px',
                maxWidth: '400px',
                margin: '0 auto',
                boxSizing: 'border-box',
              }}
            >
              <h1><FormattedMessage id='page404.title' defaultMessage='Nažalost, stranica nije pronađena!' /></h1>
              <p><FormattedMessage id='page404.description' defaultMessage='Nažalost, nismo uspjeli pronaći stranicu koju tražite. Možda ste pogriješili u URL-u?' /></p>
              <Link to={constants.routes.HOME.path}><FormattedMessage id='page404.link.home' defaultMessage='Povratak na početnu stranicu' /></Link>
            </div>
          </div>
        </section>
        <Footer />
        <BackToTop />
      </NavbarProvider>
    </Loader>
  );
};

export default Page404;
