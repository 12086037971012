import React, { useState, useEffect,useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import Language from './Language';
import { FormattedMessage } from 'react-intl';
import constants from '../../constants';
import './HeaderStyle.css';
import { useNavbar } from './../../contexts/navbarContext';

const Header = () => {
  const { expanded, toggleNavbar, styles } = useNavbar();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarOpacity, setNavbarOpacity] = useState(0.5);
  const [isNavbarHovered, setIsNavbarHovered] = useState(false);

  const linkStyle = {
    color: '#323435',
    cursor: 'pointer',
    textDecoration: 'none',
  };

  const memoizedToggleNavbar = useCallback(() => {
    if (expanded) {
      toggleNavbar();
    }
  }, [expanded, toggleNavbar]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setScrollPosition(currentScrollPosition);
      const newOpacity = currentScrollPosition === 0.5 ? 0.5 : 0.8;
      setNavbarOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavbarMouseEnter = () => {
    if(navbarOpacity  === 0.8){
      setNavbarOpacity(1);
      setIsNavbarHovered(true);
    }
  };

  const handleNavbarMouseLeave = () => {
    if(navbarOpacity  === 1){
      setNavbarOpacity(0.8);
      setIsNavbarHovered(true);
    }
  };

  return (
    <header className={`header-area ${expanded ? 'sticky' : ''}`}>
      <div 
        className={`navbar-area fixed-top ${!expanded ? 'expanded' : ''}`}
        style={{ opacity: navbarOpacity}}>       
          <Navbar expanded={expanded} expand="lg">
            <Navbar.Brand as={RouterLink} to={constants.routes.HOME.path} className="d-inline-block align-top">
              <img className="header-logo" src="assets/images/logo/logo.svg" alt="Logo" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarSupportedContent" onClick={toggleNavbar}>
              {expanded ? (
                <span className="close-icon">X</span>
              ) : (
                <>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </>
              )}
            </Navbar.Toggle>

            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="ml-auto align-items-center">
                <Nav.Item>
                  <ScrollLink to="home" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.home' defaultMessage='Početna' />
                  </ScrollLink>
                </Nav.Item>
                <Nav.Item>
                  <ScrollLink to="about" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.aboutus' defaultMessage='O nama' />
                  </ScrollLink>
                </Nav.Item>
                <Nav.Item>
                  <ScrollLink to="courses" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.services' defaultMessage='Usluge' />
                  </ScrollLink>
                </Nav.Item>
                <Nav.Item>
                  <ScrollLink to="team" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.team' defaultMessage='Tim' />
                  </ScrollLink>
                </Nav.Item>
                <Nav.Item>
                  <ScrollLink to="partners" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.partners' defaultMessage='Partneri' />
                  </ScrollLink>
                </Nav.Item>
                <Nav.Item>
                  <ScrollLink to="contact" style={linkStyle} smooth={true} duration={500} className="nav-link" onClick={memoizedToggleNavbar}>
                    <FormattedMessage id='onepager.header.contact' defaultMessage='Kontakt' />
                  </ScrollLink>
                </Nav.Item>
                {/* <Nav.Item className="ml-auto">
                  <Language closeNavbar={memoizedToggleNavbar} />
                </Nav.Item>*/}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
      </div>

      <style>
        {`
          .react-multiple-carousel__arrow {
            position: ${expanded ? 'unset' : 'absolute'};
          }

          .back-to-top {
            display: ${expanded ? 'none !important' : 'block !important'};
            position: ${expanded ? 'unset !important' : 'fixed !important'};
          }
        `}
      </style>
    </header>
  );
};

export default Header;