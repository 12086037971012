import React, { useState, useEffect } from 'react';

const NextSectionArrow = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToNextSection = () => {
    const nextSection = document.getElementById('about');
    if (nextSection) {
      const nextSectionOffset = nextSection.offsetTop;
      window.scrollTo({ top: nextSectionOffset, behavior: 'smooth' });
    }
  };

  return (
    <div>
      {isVisible && (
        <div
          className="back-to-top"
          style={{ display: 'block' }}
          onClick={scrollToNextSection}
        >
          <i className="lni lni-chevron-down"></i>
        </div>
      )}
    </div>
  );
};

export default NextSectionArrow;
