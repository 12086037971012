import roles from './roles';

export default Object.freeze({
    HOME: {
      path: '/'
    },
    CLIENTS: {
      path: '/klijenti'
    },
    TRAINERS: {
        path: '/treneri'
      },
    SOLUTIONS: {
      path: '/usluge'
    },
    NOTFOUND: {
        path: '/404'
    },
    TEAM: {
      path: '/tim'
    },
    PRIVACY: {
      path: '/privatnost'
    },
    TERMS: {
      path: '/uvjeti'
    },
    LOGIN: {
      path: '/login'
    },
    DASHBOARD: {
      path: '/dashboard',
      roleIds: [roles.ADMIN.id, roles.MODERATOR.id]
    }
});