import React, { useState } from 'react';
import './ClientsAboutUs.css';
import ContactFormModal from './ContactFormModal';
import { FormattedMessage } from 'react-intl';

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const PoslovnaRjesenjaItem = ({ number, title, instructor, onButtonClick }) => (
  <div className="col-lg-4 col-sm-6">
    <div className="single_schedule mt-30 hoverable-div">
      <span className="number">{number}</span>
      <h4 className="title">{title}</h4>
      <p className="instructor">{instructor}</p>
      <button className="open-modal-button" onClick={() => { 
        onButtonClick(title);
      }}>
        <FormattedMessage id='onepager.services.button' defaultMessage='Pošalji upit' />
      </button>
    </div>
  </div>
);

const PoslovnaRjesenjaAboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const useWebP = isWebPSupported();

  const openModal = (title) => {
    setSelectedItem(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSendEmail = (formData) => {
    closeModal();
  };

  const scheduleData = [
    { number: '01.', title: <FormattedMessage id='onepager.services.solutions.title' defaultMessage='Unapređenje poslovanja' />, instructor: <FormattedMessage id='onepager.services.solutions.title.text' defaultMessage='Rješenja za podizanje kvalitete pružanja usluga i smanjivanje operativnih troškova te edukacije zaposlenika.' />, },
  ];

  return (
    <section id="schedules" className="schedule_area pt-105 pb-120 bg_cover" 
      style={{backgroundImage: `url(${useWebP ? 'assets/images/background/webp/fitnes-centri.webp' : 'assets/images/background/fitnes-centri.jpg'})`}}>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="section_title section_title_2 text-center pb-25">
            <span className="line"></span>
            <h3 className="title"><FormattedMessage id='onepager.services.solutions.linkText' defaultMessage='Unapređenje poslovanja' /></h3>
          </div>
        </div>
      </div>
      <div className="row" style={{justifyContent: "center"}}>
        {scheduleData.map((schedule, index) => (
          <PoslovnaRjesenjaItem key={index} {...schedule} onButtonClick={openModal} />
        ))}
      </div>

      {/* Render the modal only if isModalOpen is true */}
      {isModalOpen && (
        <ContactFormModal
          selectedItem={selectedItem}
          onClose={closeModal}
          onSend={handleSendEmail}
        />
      )}
    </section>
  );
};

export default PoslovnaRjesenjaAboutUs;
