import React, { useState, useEffect, useRef } from 'react';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Sidebar from './Sidebar';
import MainContent from './Maincontent';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../../contexts/authContext';

const { Header } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 767);
  const { enqueueSnackbar } = useSnackbar();
  const welcomeDisplayedRef = useRef(false);
  const auth = useAuth();
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleMenuItemClick = (component) => {
    setSelectedComponent(component);
  };

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    // Display welcome message only if it hasn't been displayed before
    if (!welcomeDisplayedRef.current) {
      enqueueSnackbar(
        <>
          <FormattedMessage id='features.blog.welcome' defaultMessage='Dobro došao: ' />
          {auth.user && auth.user.Email && <span>{auth.user.Email}</span>}
        </>,
        { variant: 'success' }
      );
      welcomeDisplayedRef.current = true;
    }
  }, [enqueueSnackbar, auth.user]);

  const handleSignOut = () => {
    // Handle sign out logic here
    auth.signOut();
  };

  const renderCollapsedLayout = () => (
    <Header
      className="site-layout-background"
      style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <div className="toggle-button" onClick={toggle}>
        {collapsed ? (
          <MenuUnfoldOutlined style={{ fontSize: 18 }} />
        ) : (
          <MenuFoldOutlined style={{ fontSize: 18 }} />
        )}
        {collapsed ? <span style={{ marginLeft: 8 }}>Open Menu</span> : <span style={{ marginLeft: 8 }}>Close Menu</span>}
      </div>
      <div style={{ marginLeft: 16 }}>
        <Button type="primary" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>
    </Header>
  );

  const renderExpandedLayout = () => (
    <Header
      className="site-layout-background"
      style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    >
      <div style={{ marginLeft: 'auto', marginRight: 16 }}>
        <Button type="primary" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>
    </Header>
  );

  return (
    <Layout>
      <Sidebar collapsed={collapsed} handleMenuItemClick={handleMenuItemClick} />
      <Layout className="site-layout">
        {collapsed ? renderCollapsedLayout() : renderExpandedLayout()}
        <MainContent selectedComponent={selectedComponent} />
      </Layout>
    </Layout>
  );
};

export default Dashboard;