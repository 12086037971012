import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { sendMail } from '../../features/webmail/webMailService'
import SpinnerLoader from './GridLoader';


const locations = [
    {
      lat: 45.802016758510185,
      lng: 15.944848116032599,
      title: 'MoFit Fitness Club Zagreb',
      address: 'Selska 81, (Zorkovačka ulica 6), 10000, Zagreb',
    },
    {
      lat: 45.81325328495401,
      lng: 15.972523201925968,
      title: 'Adidas Sports studio - fitness club',
      address: 'adidas shop, Ilica 21 - 3. kat, Ilica, 10000, Zagreb',
    },
  ];
  
  const Contact = () => {
    const intl = useIntl();
    const mapRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
    });

    const validateForm = () => {
      const { name, email, subject, message } = formData;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if (!name || !email || !subject || !message) {
        // Show error message for empty fields
        enqueueSnackbar(
          <FormattedMessage
            id="onepager.contact.form.emptyFields"
            defaultMessage="All fields are required."
          />,
          { variant: 'error' }
        );
        return false;
      }

      if (!emailRegex.test(email)) {
        // Show error message for invalid email format
        enqueueSnackbar(
          <FormattedMessage
            id="onepager.contact.form.invalidEmail"
            defaultMessage="Please enter a valid email address."
          />,
          { variant: 'error' }
        );
        return false;
      }
  
      return true;
    };

    const handleResetForm = () => {
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    };

    const [popupMessage, setPopupMessage] = useState(null);

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSend = async (e) => {
      e.preventDefault();

      if (!validateForm()) {
        return;
      }

      try {
        setLoading(true);

        const result = await sendMail({
          Name: formData.name,
          Email: formData.email,
          Subject: formData.subject,
          Message: formData.message,
        });

        if (result.isMailSent) {
          // Show success message
          enqueueSnackbar(<FormattedMessage id='onepager.contact.mail.ok.message' defaultMessage='Mail uspješno poslan' />
          , { variant: 'success' });
          handleResetForm();
        } else {
          // Show error message
          enqueueSnackbar(<FormattedMessage id='onepager.contact.mail.notok.message' defaultMessage='Mail nije poslan' />
          , { variant: 'error' });
        }
      } catch (error) {
        // Show generic error message
        enqueueSnackbar(<FormattedMessage id='onepager.contact.mail.error' defaultMessage='Greška pri slanju maila' />
        , { variant: 'error' });
      }

      setLoading(false);
    };

    useEffect(() => {
      if (popupMessage) {
        // Set a timeout to clear the popup message after a few seconds
        const timeoutId = setTimeout(() => {
          setPopupMessage(null);
        }, 3000);
  
        // Clean up the timeout when the component is unmounted
        return () => clearTimeout(timeoutId);
      }
    }, [popupMessage]);
  
    useEffect(() => {
      if (!mapRef.current) {
        mapRef.current = L.map('leaflet_map').setView([45.801953210839336, 15.944848116032599], 13);
  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
        }).addTo(mapRef.current);
  
        locations.forEach((location) => {
          const marker = L.marker([location.lat, location.lng])
            .addTo(mapRef.current)
            .bindPopup(
              `<b>${location.title}</b><br/>${location.address}<br/><a href="#" class="directions-link" data-lat="${location.lat}" data-lng="${location.lng}">Kako do nas</a>`
            );
  
          marker.bindTooltip(location.title, { permanent: true, direction: 'top', className: 'marker-label' });
        });
  
        // Event listener for links inside popups
        mapRef.current.on('popupopen', (event) => {
          const link = event.popup._contentNode.querySelector('.directions-link');
  
          if (link) {
            link.addEventListener('click', handleDirectionsClick);
          }
        });
      }
    }, []);
  
    // Function to open Google Maps with directions
    function handleDirectionsClick(event) {
      event.preventDefault();
      const lat = event.target.getAttribute('data-lat');
      const lng = event.target.getAttribute('data-lng');
      const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
      window.open(url, '_blank');
    }

    return (
        <section id="contact" className="contact_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact_form pt-105 pb-120">
                <div className="section_title pb-25">
                  <span className="line"></span>
                  <h3 className="title" style={{ color: '#000000' }}>
                    <FormattedMessage id='onepager.contact.title' defaultMessage='Kontaktirajte nas' />
                  </h3>
                </div>
                <form>
                  <div className="single_form">
                    <input
                      type="text"
                      name="name"
                      placeholder={intl.formatMessage({ id: 'onepager.contact.name' })}
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="single_form">
                    <input
                      type="text"
                      name="email"
                      placeholder={intl.formatMessage({ id: 'onepager.contact.email' })}
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="single_form">
                    <input
                      type="text"
                      name="subject"
                      placeholder={intl.formatMessage({ id: 'onepager.contact.subject' })}
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="single_form">
                    <textarea
                      name="message"
                      placeholder={intl.formatMessage({ id: 'onepager.contact.message' })}
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <p className="form-message"></p>
                  <div className="single_form">
                    <button className="main-btn" style={{ backgroundColor: '#d12d3c' }} onClick={handleSend} disabled={loading}>
                      <FormattedMessage id='onepager.contact.send' defaultMessage='POŠALJI' />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

          <div className="contact_map">
          <div id="leaflet_map" style={{ width: '100%', height: '100%', position: 'relative', zIndex: 20 }}></div>
          </div>
          <SpinnerLoader loading={loading} />
      </section>
    );
  };

export default Contact;
