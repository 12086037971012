import { FormattedMessage } from 'react-intl';

const teamData = [
  {
    name: 'Nikola Dino Badovinac',
    imagePath: 'assets/images/trainers/medium/medium_nikola-dino-badovinac.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_nikola-dino-badovinac-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_nikola-dino-badovinac.jpg 322w,
      assets/images/trainers/medium/medium_nikola-dino-badovinac.jpg 644w,
      assets/images/trainers/high/high_nikola-dino-badovinac.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_nikola-dino-badovinac-color.jpg 322w,
      assets/images/trainers/medium/medium_nikola-dino-badovinac-color.jpg 644w,
      assets/images/trainers/high/high_nikola-dino-badovinac-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/nikola-dino-badovinac.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/nikola-dino-badovinac-color.webp',
    imageQr: 'assets/images/trainers/Nikola_Dino_Badovinac_qr.png',
    linkQr: 'https://forms.gle/NeMuS3xFbmW6UmABA',
    role: <FormattedMessage id='onepager.team.leader' defaultMessage='Osnivač i voditelj coach tribea' />,
    text: <FormattedMessage id='onepager.team.nikola.dino.badovinac' />,
    instagram: 'https://www.instagram.com/fitnessbynikola/',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Slaven Krajačić',
    imagePath: 'assets/images/trainers/medium/medium_slaven-krajacic.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_slaven-krajacic-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_slaven-krajacic.jpg 322w,
      assets/images/trainers/medium/medium_slaven-krajacic.jpg 644w,
      assets/images/trainers/high/high_slaven-krajacic.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_slaven-krajacic-color.jpg 322w,
      assets/images/trainers/medium/medium_slaven-krajacic-color.jpg 644w,
      assets/images/trainers/high/high_slaven-krajacic-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/slaven-krajacic.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/slaven-krajacic-color.webp',
    imageQr: 'assets/images/trainers/Slaven_Krajacic_qr.png',
    linkQr: 'https://forms.gle/JGnypFcvo6xxpLCZ7',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.krajcic.slaven' />,
    instagram: '',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Ivica Jurkić',
    imagePath: 'assets/images/trainers/medium/medium_ivica-jurkic.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_ivica-jurkic-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_ivica-jurkic.jpg 322w,
      assets/images/trainers/medium/medium_ivica-jurkic.jpg 644w,
      assets/images/trainers/high/high_ivica-jurkic.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_ivica-jurkic-color.jpg 322w,
      assets/images/trainers/medium/medium_ivica-jurkic-color.jpg 644w,
      assets/images/trainers/high/high_ivica-jurkic-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/ivica-jurkic.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/ivica-jurkic-color.webp',
    imageQr: 'assets/images/trainers/Ivica_Jurkic_qr.png',
    linkQr: 'https://forms.gle/4yFPDCZbTrA8BkPz7',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.ivica.jurkic' />,
    instagram: 'https://www.instagram.com/ivica.jurkic?igsh=MXFudzVueWU5MnRqdg==',
    facebook: 'https://www.facebook.com/profile.php?id=100001283690434',
    linkedIn: ''
  },
  {
    name: 'Stjepan Žabčić',
    imagePath: 'assets/images/trainers/medium/medium_stjepan-zabcic.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_stjepan-zabcic-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_stjepan-zabcic.jpg 322w,
      assets/images/trainers/medium/medium_stjepan-zabcic.jpg 644w,
      assets/images/trainers/high/high_stjepan-zabcic.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_stjepan-zabcic-color.jpg 322w,
      assets/images/trainers/medium/medium_stjepan-zabcic-color.jpg 644w,
      assets/images/trainers/high/high_stjepan-zabcic-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/stjepan-zabcic.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/stjepan-zabcic-color.webp',
    imageQr: 'assets/images/trainers/Stjepan_Zabcic_qr.png',
    linkQr: 'https://forms.gle/wWUZpfrDTKmd5CJB6',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.zapcic.stjepan' />,
    instagram: 'https://www.instagram.com/stjepanzab?igsh=MWhvbzFueXoyaHlyMQ%3D%3D&utm_source=qr',
    facebook: 'https://www.facebook.com/profile.php?id=100088552206136',
    linkedIn: ''
  },
  {
    name: 'Marko Šango',
    imagePath: 'assets/images/trainers/medium/medium_marko-sango.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_marko-sango-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_marko-sango.jpg 322w,
      assets/images/trainers/medium/medium_marko-sango.jpg 644w,
      assets/images/trainers/high/high_marko-sango.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_marko-sango-color.jpg 322w,
      assets/images/trainers/medium/medium_marko-sango-color.jpg 644w,
      assets/images/trainers/high/high_marko-sango-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/marko-sango.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/marko-sango-color.webp',
    imageQr: 'assets/images/trainers/Marko_Sango_qr.png',
    linkQr: 'https://forms.gle/3xfxRXHztEZJKo3M7',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.sango.marko' />,
    instagram: 'https://www.instagram.com/marko_sango?utm_source=qr&igsh=ejZzOGM0MXhlMDRx',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Antonija Jelečanin',
    imagePath: 'assets/images/trainers/medium/medium_antonija-jelecanin.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_antonija-jelecanin-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_antonija-jelecanin.jpg 322w,
      assets/images/trainers/medium/medium_antonija-jelecanin.jpg 644w,
      assets/images/trainers/high/high_antonija-jelecanin.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_antonija-jelecanin-color.jpg 322w,
      assets/images/trainers/medium/medium_antonija-jelecanin-color.jpg 644w,
      assets/images/trainers/high/high_antonija-jelecanin-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/antonija-jelecanin.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/antonija-jelecanin-color.webp',
    imageQr: 'assets/images/trainers/Antonija_Jelecanin_qr.png',
    linkQr: 'https://forms.gle/8Lj316LHGyc6Bh748',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.jelacanin.antonija' />,
    instagram: 'https://www.instagram.com/antonija.jelecanin?igsh=b3dyYXl4bzBuY2c2',
    facebook: 'https://www.facebook.com/antonija.jelecanin',
    linkedIn: ''
  },
  {
    name: 'Marin Bago',
    imagePath: 'assets/images/trainers/medium/medium_marin-bago.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_marin-bago-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_marin-bago.jpg 322w,
      assets/images/trainers/medium/medium_marin-bago.jpg 644w,
      assets/images/trainers/high/high_marin-bago.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_marin-bago-color.jpg 322w,
      assets/images/trainers/medium/medium_marin-bago-color.jpg 644w,
      assets/images/trainers/high/high_marin-bago-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/marin-bago.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/marin-bago-color.webp',
    imageQr: 'assets/images/trainers/Marin_Bago_qr.png',
    linkQr: 'https://forms.gle/GRCb9mv6y7xGicHMA',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.marin.bago' />,
    instagram: '',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Ena Stefanov',
    imagePath: 'assets/images/trainers/medium/medium_ena-stefanov.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_ena-stefanov-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_ena-stefanov.jpg 322w,
      assets/images/trainers/medium/medium_ena-stefanov.jpg 644w,
      assets/images/trainers/high/high_ena-stefanov.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_ena-stefanov-color.jpg 322w,
      assets/images/trainers/medium/medium_ena-stefanov-color.jpg 644w,
      assets/images/trainers/high/high_ena-stefanov-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/ena-stefanov.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/ena-stefanov-color.webp',
    imageQr: 'assets/images/trainers/Ena_Stefanov_qr.png',
    linkQr: 'https://forms.gle/txuNPmzMSvdsn9Kj7',
    role: <FormattedMessage id='onepager.team.nutricionist' defaultMessage='Nutricionist' />,
    text: <FormattedMessage id='onepager.team.ena.stefanov' />,
    instagram: 'https://www.instagram.com/enastefanov4?igsh=N2l6ZDIxb2JpMGN3',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Tomislav Đurović',
    imagePath: 'assets/images/trainers/medium/medium_tomislav-durovic.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_tomislav-durovic-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_tomislav-durovic.jpg 322w,
      assets/images/trainers/medium/medium_tomislav-durovic.jpg 644w,
      assets/images/trainers/high/high_tomislav-durovic.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_tomislav-durovic-color.jpg 322w,
      assets/images/trainers/medium/medium_tomislav-durovic-color.jpg 644w,
      assets/images/trainers/high/high_tomislav-durovic-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/tomislav-durovic.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/tomislav-durovic-color.webp',
    imageQr: 'assets/images/trainers/Tomislav_Durovic_qr.png',
    linkQr: 'https://forms.gle/KAYTGKBK8qbqkyys5',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.tomislav.durovic' />,
    instagram: 'https://www.instagram.com/tomislav_durovic?igsh=MWxnc21xamJ3Zzk1bA==',
    facebook: '',
    linkedIn: ''
  },
  {
    name: 'Toni Solak',
    imagePath: 'assets/images/trainers/medium/medium_toni-solak.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_toni-solak-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_toni-solak.jpg 322w,
      assets/images/trainers/medium/medium_toni-solak.jpg 644w,
      assets/images/trainers/high/high_toni-solak.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_toni-solak-color.jpg 322w,
      assets/images/trainers/medium/medium_toni-solak-color.jpg 644w,
      assets/images/trainers/high/high_toni-solak-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/toni-solak.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/toni-solak-color.webp',
    imageQr: 'assets/images/trainers/Toni_Solak_qr.png',
    linkQr: 'https://forms.gle/ZUpsQWVTWdYnZrEe9',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.toni.solak' />,
    instagram: 'https://www.instagram.com/tonisolak?igsh=MWE4Z2Zla3k0ZWw2aA==',
    facebook: 'https://www.facebook.com/toni.solak',
    linkedIn: ''
  },
  {
    name: 'Bruno Hemen',
    imagePath: 'assets/images/trainers/medium/medium_bruno-hemen.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_bruno-hemen-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_bruno-hemen.jpg 322w,
      assets/images/trainers/medium/medium_bruno-hemen.jpg 644w,
      assets/images/trainers/high/high_bruno-hemen.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_bruno-hemen-color.jpg 322w,
      assets/images/trainers/medium/medium_bruno-hemen-color.jpg 644w,
      assets/images/trainers/high/high_bruno-hemen-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/bruno-hemen.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/bruno-hemen-color.webp',
    imageQr: 'assets/images/trainers/Bruno_Hemen_qr.png',
    linkQr: 'https://forms.gle/AY85FWqo6qipTrht6',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.bruno.hemen' />,
    instagram: 'https://www.instagram.com/bruno.hemen/',
    facebook: 'https://www.facebook.com/bruno.hemen/',
    linkedIn: ''
  },
  {
    name: 'Tommi Lovak',
    imagePath: 'assets/images/trainers/medium/medium_tommi-lovak.jpg',
    imageHovered: 'assets/images/trainers/medium/medium_tommi-lovak-color.jpg',
    imagePathSrcSet: `
      assets/images/trainers/low/low_tommi-lovak.jpg 322w,
      assets/images/trainers/medium/medium_tommi-lovak.jpg 644w,
      assets/images/trainers/high/high_tommi-lovak.jpg 966w
    `,
    imageHoveredSrcSet: `
      assets/images/trainers/low/low_tommi-lovak-color.jpg 322w,
      assets/images/trainers/medium/medium_tommi-lovak-color.jpg 644w,
      assets/images/trainers/high/high_tommi-lovak-color.jpg 966w
    `,
    imagePathWebp: 'assets/images/trainers/webp/tommi-lovak.webp',
    imageHoveredWebp: 'assets/images/trainers/webp/tommi-lovak-color.webp',
    imageQr: 'assets/images/trainers/tommi_lovak_qr.png',
    linkQr: 'https://forms.gle/p5kS71K2uFG57ZvX9',
    role: <FormattedMessage id='onepager.team.trainer' defaultMessage='Fitnes Trener' />,
    text: <FormattedMessage id='onepager.team.tommi.lovak' />,
    instagram: 'https://www.instagram.com/tommilovak?igsh=OGU0MmVlOWVjOQ==',
    facebook: '',
    linkedIn: ''
  }
];

export default teamData;
