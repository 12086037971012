import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [styles, setStyles] = useState({
    arrow: { position: 'absolute' },
    backToTop: { position: 'fixed' },
  });

  const toggleNavbar = () => {
    setExpanded(!expanded);

    setStyles({
      arrow: { position: expanded ? 'unset' : 'absolute' },
      backToTop: { position: expanded ? 'unset' : 'fixed' },
    });
  };

  return (
    <NavbarContext.Provider value={{ expanded, toggleNavbar, styles }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error('useNavbar must be used within a NavbarProvider');
  }
  return context;
};