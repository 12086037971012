import React, { useState } from 'react';
import HeaderServices from './../components/onepager/HeaderServices';
import Footer from './../components/onepager/Footer';
import BackToTop from './../components/onepager/BackToTop';
import Loader from './../components/onepager/Loader';
import ClientsAboutUs from '../components/onepager/ClientsAboutUs';
import { NavbarProvider } from '../contexts/navbarContext';


const Clients = () => {

  const [loading, setLoading] = useState(true);

  return (
    <Loader loading={loading} setLoading={setLoading}>
      <NavbarProvider>
        <div style={{backgroundColor: '#F5F5F5'}}>
          <HeaderServices />
          <ClientsAboutUs />
          <Footer />
          <BackToTop />
          {/* Add your content for the Clients page */}
        </div>
      </NavbarProvider>
    </Loader>
  );
};

export default Clients;