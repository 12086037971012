import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const BlogPostForm = () => {
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  const onFinish = async (values) => {
    // Combine form values with the image file
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('image', image);

    // Perform submit actions here
    console.log('Form values:', values);
    console.log('Image file:', image);
  };

  const beforeUpload = (file) => {
    // Set image state when a file is selected
    setImage(file);
    return false; // Prevent default upload behavior
  };

  const uploadProps = {
    beforeUpload,
    accept: 'image/*',
    showUploadList: false,
  };

  return (
    <div>
      <h2>Create a Blog Post</h2>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Content"
          name="content"
          rules={[{ required: true, message: 'Please enter the content' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Image">
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Select Image</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BlogPostForm;
