import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

function isWebPSupported() {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
}

const Counter = () => {
  const useWebP = isWebPSupported();
  const counters = [
    //{ id: 1, icon: 'lni lni-users', count: 5232, label: <FormattedMessage id='onepager.counter.clients' defaultMessage='Zadovoljnih klijenata' /> },
    { id: 2, icon: 'lni lni-map-marker', count: 2, label: <FormattedMessage id='onepager.counter.locations' defaultMessage='Lokacije' /> },
    { id: 3, icon: 'lni lni-user', count: 12, label: <FormattedMessage id='onepager.counter.trainers' defaultMessage='Treneri' /> },
    { id: 4, icon: 'lni lni-cup', count: 1, label: <FormattedMessage id='onepager.counter.goal' defaultMessage='Cilj' /> },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${useWebP ? 'assets/images/background/webp/counter_bg.webp' : 'assets/images/background/medium/medium_counter_bg.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    srcSet: useWebP ? null : 'assets/images/background/low/low_counter_bg.jpg 322w, assets/images/background/medium/medium_counter_bg.jpg 644w, assets/images/background/high/high_counter_bg.jpg 966w'
  };

  const [animatedCounters, setAnimatedCounters] = useState({});
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (isAnimationComplete || !inView) {
      return; // Do not start the animation if it's already complete or not in view
    }

    const animationDuration = 1500; // 3 seconds
    const updateInterval = 50; // Update interval in milliseconds

    const updateCounters = () => {
      const startTime = Date.now();

      const update = () => {
        const now = Date.now();
        const progress = Math.min(1, (now - startTime) / animationDuration);

        const updatedCounters = {};
        let allCountersComplete = true;

        counters.forEach((counter) => {
          const animatedValue = Math.floor(progress * counter.count);
          updatedCounters[counter.id] = animatedValue; // Use id as the key

          if (animatedValue < counter.count) {
            allCountersComplete = false;
          }
        });

        setAnimatedCounters(updatedCounters);

        if (now < startTime + animationDuration && !allCountersComplete) {
          requestAnimationFrame(update);
        } else {
          setIsAnimationComplete(true);
        }
      };

      requestAnimationFrame(update);
    };

    const intervalId = setInterval(updateCounters, updateInterval);

    return () => clearInterval(intervalId);
  }, [counters, isAnimationComplete, inView]);

  return (
    <section
      id="counter"
      className="counter_area pt-70 pb-120 bg_cover"
      style={backgroundStyle }
      ref={ref}
    >
      <div className="container">
        <div className="row">
          {counters.map((counter, index) => (
            <div key={index} className="col-md-4 col-12">
              <div className="single_counter text-center mt-40">
                <i className={counter.icon}></i>
                <span className="count counter">{animatedCounters[counter.id] || 0}</span>
                <p>{counter.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counter;
